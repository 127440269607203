import Items from "../../Items";




const HomePage = () => {
    return (
        <>
            <Items />
        </>
    )
}

export default HomePage;